<template>
    <div class="filters-wrapper">
        <div :class="{search: true, searching: this.searching}">
            
            <div v-if="this.filtersdata.length>0 && !this.filtersOpen" class="filtersbutton" @click="this.openFilters">
                <img :src="buttonsrc" alt="open filters ">
            </div>

            <img v-if="!this.searchField.length"
                @click="this.focusInput" src="../assets/search-icon.png" alt="search icon" 
                :class="{'search-icon': true, 'searching': this.searching}" >
            <img v-if="this.searchField.length"
                @click="searchField=''" src="close.svg" alt="delete search" 
                :class="{'search-icon': true, 'searching': this.searching, 'delete-search': true}" >
            
            <form @submit.prevent="searchInput" id="search_from">
                <input type="text" v-model="searchField" placeholder="Cerca ..." id="search_input" class="searching">
            </form>
            

            <div class="desktop-filters">
                <div v-for="(filter, index) in this.filtersdata" :key="'filter' + index" class="filter"
                    :style="hoverStyle(filter.attributes.drupal_internal__tid)"
                    @click="combineFilter(filter.attributes.drupal_internal__tid)">
                </div>
            </div>

            <h1 :class="{'title': true, 'searching': this.searching, 'num': this.activefilters.length}">
                {{ this.title }} 
            </h1>

        </div>

        <div v-if="this.filtersOpen && this.filtersdata.length>0" class="mobile-filters">
            <div class="back-cover"></div>
            <div class="filters-cover">
                <div v-if="this.filtersdata.length>0 && this.filtersOpen" class="closefilters" @click="this.openFilters">
                    <img :src="buttonsrc" alt="close button">
                </div>
                <h3>Filtra per</h3>
                <div class="inner">
                    <div v-for="(filter, index) in this.filtersdata" 
                        :key="'filter' + index" 
                        :class="{'filter': true, 'attivo': mobileFilters.includes(filter.attributes.drupal_internal__tid) }"
                        @click="addMobileFilter(filter.attributes.drupal_internal__tid)"
                        :style="hoverStyle(filter.attributes.drupal_internal__tid)">
                        <p>{{ filter.attributes.name }}</p>
                    </div>
                </div>
                <div class="buttons">
                    <button class="light-btn slim" @click="reset">Cancella tutto</button>
                    <button class="btn slim" @click="filtraMobile">Mostra risultati</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fieldColorProject, fieldColorExpert,
        fieldSrcProject, fieldSrcExpert,
        fieldSrcDarkProject, fieldSrcDarkExpert } from '../libs/utils.js'
export default {
    name: 'filters-bar',
    props: {
        filtersdata: Array,
        activefilters: Array,
        title: String,
        type: String
    }, 
    data: () => {
        return {
            searching: false,
            filtersOpen: false,
            mobileFilters: [],
            searchField: "",
            windowwidth: 0
        }
    },
    computed: {
        buttonsrc() {
            return this.filtersOpen ? 'close.svg' : 'funnel.svg'
        },
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        }
    },
    methods: {
        searchInput() {
            document.getElementById('search_input').blur()
            this.$emit('search', this.searchField)
        },
        filterColor(filter) {
            if(this.type=='projects') {
                return '--color: ' + fieldColorProject(filter) + ';'
            } else if(this.type=='experts') {
                return '--color: ' + fieldColorExpert(filter) + ';'
            }
        },
        reset() {
            this.mobileFilters = []
            this.$emit('bash-combine', this.mobileFilters)
            //this.filtersOpen = false
        },
        addMobileFilter(filter) {
            if(this.mobileFilters.includes(filter))
                this.mobileFilters.splice(this.mobileFilters.indexOf(filter), 1)
            else
                this.mobileFilters.push(filter)
        },
        filtraMobile() {
            document.body.classList.toggle('no-scroll')
            this.$emit('bash-combine', this.mobileFilters)
            this.filtersOpen = false
        },
        hoverStyle(filter) {
            var col = ''
            var back = ''
            var dback = ''
            this.type=='projects' ? col=fieldColorProject(filter) : col=fieldColorExpert(filter)
            this.type=='projects' ? back=fieldSrcProject(filter) : back=fieldSrcExpert(filter)
            this.type=='projects' ? dback=fieldSrcDarkProject(filter) : dback=fieldSrcDarkExpert(filter)

            var ff = this.filtersdata.find(f => f.attributes.drupal_internal__tid == filter)
            var name = ff.attributes.name
            var l = name.length

            return '--color-hover: ' + col + ';' +
                '--background-hover: url(' + back + ');' +
                '--background-image: url(' + dback + ');' + 
                '--filtername: "' + name + '";' +
                '--filterlength: ' + l*8 + 'px ;'
        },
        fieldSrc(image) {
            var p = require.context('../assets/', false, /\.svg$/)
            var t = this.type=='projects' ? './field-project-dark-' : './field-dark-'
            return p(t + image + '.svg')
        },
        focusInput() {
            if(this.windowwidth < 992) {
                this.searching = !this.searching
                if(this.searching)
                    setTimeout(() => {
                        document.getElementById('search_input').focus();
                    }, 100);
            }
            
        },
        openFilters() {
            document.body.classList.toggle('no-scroll')
            this.filtersOpen = !this.filtersOpen
        },
        combineFilter(tid) {
            event.target.classList.toggle('active')
            this.$emit('combine-filter', tid)
        }
    },
    beforeDestroy() {
        this.searching = false;
        this.filtersOpen = false;
        document.body.classList.remove('no-scroll');
    },
    mounted() {
        this.windowwidth = window.innerWidth

        if(this.windowwidth>=992) {
            this.searching = true
        }

        window.addEventListener('resize', () => {
            this.windowwidth = window.innerWidth
        })

        console.log(this.filtersdata)
    },
     watch: {
        searchField() {
            if(this.searchField=='') {
                document.getElementById('search_input').focus()
                this.$emit('search', this.searchField)
            }
        },
        searching() {
            if(!this.searching)
                this.searchField = ""
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
input.searching { 
    border-radius: 0;
    border-bottom: 1px solid $mainColor; 
}
</style>