<template>
    <div id="totop" class="no-header-template pattern-bg">
        <div class="container-espe fullwidth filters-bar title-bar endborder white-bg">
            <h1>Gli esperti <span v-if="this.activeFilters.length">{{ activeFilters.length }}</span></h1>
        </div>

        <div class="container-espe fullwidth filters-bar endborder white-bg">
            <filters-bar 
                type="experts"
                :filtersdata="this.filtersData" 
                :activefilters="this.activeFilters" 
                :title="this.pageData.attributes.title" 
                @search="handleSearch" 
                @combine-filter="handleFiltering" 
                @bash-combine="handleBashFiltering">
            </filters-bar>
        </div>

        <div class="container-espe pattern-bg">
            <div class="experts" >
                <expert-tile v-for="(expert, index) in this.filteredExperts" :key="'expert'+index" 
                    :expertdata="expert" 
                    :filtersdata="filtersData"
                    :routes="routesData">
                </expert-tile>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchNodes, fetchTaxonomy, fetchRoutes } from '../libs/drupalClient'
import FiltersBar from '../components/FiltersBar.vue'
import ExpertTile from '../components/ExpertTile.vue'
export default {
    name: 'gli-esperti',
    components: {
        ExpertTile,
        FiltersBar
    },
    data: () => {
        return {
            pageData: { 
                attributes: {
                    title: "Gli Esperti",
                    body: {
                        summary: ""
                    }
                } 
            },
            currentPageId: "",
            filtersData: [],
            expertData: [],
            routesData: [],
            activeFilters: [],
            nameSearch: ""
        }
    },
    methods: {
        handleFiltering(value) {
            if(this.activeFilters.includes(value))
                this.activeFilters.splice(this.activeFilters.indexOf(value), 1)
            else
                this.activeFilters.push(value)
        },
        handleBashFiltering(value) {
            this.activeFilters = []
            value.forEach(v => {
                this.activeFilters.push(v)
            })

            window.scrollTo({
                top: document.getElementById('totop').getBoundingClientRect().top,
                behavior: 'smooth'
            })
        },
        handleSearch(value) {
            this.nameSearch = value 
        }
    },
    computed: {
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        },
        filteredExperts() {
            var finalFE = []

            if(this.activeFilters.length==0)
                finalFE = this.expertData
            else {
                var fe = []
                var found
                this.expertData.forEach(ex => {
                    found = false
                    ex.field_expert_ambito.forEach(am => {
                        if(this.activeFilters.includes(am.attributes.drupal_internal__tid))
                            found = true
                    })
                    if(found) 
                        fe.push(ex)
                })

                finalFE = fe
            }

            finalFE = finalFE.filter(fe => {
                var completename = fe.attributes.field_name + " " + fe.attributes.field_surname
                
                var amongInteressi = false
                fe.attributes.field_expert_interessi.forEach(s => {
                    if(s.toLowerCase().includes(this.nameSearch.toLowerCase()))
                        amongInteressi = true
                })

                var amongAmbiti = false
                fe.field_expert_ambito.forEach(s => {
                    if(s.attributes.name.toLowerCase().includes(this.nameSearch.toLowerCase()))
                        amongAmbiti = true
                })

                if(completename.toLowerCase().includes(this.nameSearch.toLowerCase())
                    || fe.attributes.field_expert_professione.toLowerCase().includes(this.nameSearch.toLowerCase())
                    || amongInteressi 
                    || amongAmbiti)
                    return true
                else return false
            })

            return finalFE
        }
    },
    async mounted() {
        await fetchRoutes().then(r => this.routesData = r.routes)

        await fetchNodes('expert', { include: ['field_expert_ambito', 'field_expert_photo']}, this.lang)
        .then(res => {
            this.expertData = res.filter(r => r.attributes.field_disponibilita_in_ore > 0)
        })

        fetchTaxonomy('ambito_di_esperienza', {}, this.lang).then(res => {
            this.filtersData = res
        })
    }
}
</script>

<style lang="scss">
</style>